import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import Heading from "../../components/Layout/Heading"
import Content from "../../components/Layout/Content"
import Icon from "../../components/Icon"

const Page = ({ location, pageContext }) => {
  const { page } = pageContext

  return (
    <Layout location={location}>
      <SEO title={page.title} description={page.description} />
      <Heading title={page.title} />
      <Content content={page.content} />
      <div className="row">
        <div className="col-12">
          <ul className="nav justify-content-center">
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/brisbane" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Brisbane <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/bundaberg" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Bundaberg &amp; Coral Coast{" "}
                  <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/cairns" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Cairns <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/cooloola" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Cooloola <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link
                to="/tourist-destinations/discovery-coast"
                className="nav-link"
              >
                <Button type="button" bsPrefix="cln">
                  Discovery Coast{" "}
                  <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link
                to="/tourist-destinations/fraser-island"
                className="nav-link"
              >
                <Button type="button" bsPrefix="cln">
                  Fraser Island <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/gladstone" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Gladstone <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/gold-coast" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Gold Coast <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/hervey-bay" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Hervey Bay <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/mackay" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Mackay <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link
                to="/tourist-destinations/mission-beach"
                className="nav-link"
              >
                <Button type="button" bsPrefix="cln">
                  Mission Beach <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/rockhampton" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Rockhampton <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/stanthorpe" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Stanthorpe <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link
                to="/tourist-destinations/sunshine-coast"
                className="nav-link"
              >
                <Button type="button" bsPrefix="cln">
                  Sunshine Coast <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/the-west" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  The West <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/townsville" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Townsville <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
            <li className="nav-item mx-2 mb-2">
              <Link to="/tourist-destinations/whitsundays" className="nav-link">
                <Button type="button" bsPrefix="cln">
                  Whitsundays <Icon prefix="fas" name="arrow-circle-right" />
                </Button>
              </Link>
            </li>
          </ul>
          <br />
        </div>
      </div>
    </Layout>
  )
}

export default Page
